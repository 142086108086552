import React from 'react';

import { Header } from 'components/titles/v2';
import { Flex, Container } from 'components/grids/v3';

const TutorialHistory = () => (
  <Flex.Item>
    <Container width="960px">
      <Flex direction={Flex.V}>
        <Header title="Tutoriales del sistema" />
        <Flex.Scrap>
          {/* <DateSelector on_search={on_search} on_download={on_download} /> */}
          <ul>
            <li>
              HABITACION - CHECK IN/OUT:
              La accion solo muestra el estado la habitacion si esta en uso o no.
            </li>
            <li>
              HABITACION - PAGAR: Es la accion mas importante del sistema,
              PORFAVOR asegurarse de que todos los CHECK IN completen el ciclo de PAGAR,
              una vez que el ciclo de PAGAR esta completo,
              los reportes van a empezar a registrar las ventas (CHECKOUT) y el uso de las habitaciones.
            </li>
            <li>
              CHECKOUT: Muestra el historial de las habitaciones,
              Como DUEÑO DEL HOTEL asegurarse de que la columna PAGADO este en verde:
              <ul>
                <li>
                  PAGADO: Significa que un visitante uso la habitacion y PAGO por ella.
                </li>
                <li>
                  CERRADO: Significa que un visitante uso la habitacion
                  y se fue sin pagar o se tubo que cerrar el CHECK IN por otra razon.
                </li>
              </ul>
            </li>
          </ul>
        </Flex.Scrap>
        <Flex.Item>
          <Header title="Tutorial BASICO" />
          <p>
            El tutorial muestra si la habitacion esta o no en uso,
            sin mucho detalle con los visitantes, pagos y etc.
          </p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/aqgeDeDd-iw?si=RHBgTUl4X5kDnswY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </Flex.Item>
        <Flex.Item>
          <Header title="Tutorial INTERMEDIO" />
          <p>
            El tutorial muestra es uso correcto de una habitacion
            agregar los visitantes y registrar los pagos.
          </p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0EHIP9cWw-I?si=1a6b1kYjvfD43raA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </Flex.Item>
        <Flex.Item>
          <Header title="Tutorial AVANZADO" />
          <p>
            El tutorial muestra es uso correcto de una habitacion,
            leer los reportes, abrir y cerrar la CAJA DIARIA.
          </p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/WWJYmWkhA7U?si=vaiP7yzK2tkv1icD"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </Flex.Item>
        {/* TUTORIAL BASICO
          https://youtu.be/aqgeDeDd-iw

          TUTORIAL INTERMEDIO
          https://youtu.be/0EHIP9cWw-I

          TUTORIAL AVANZADO
          https://youtu.be/WWJYmWkhA7U */}
      </Flex>
    </Container>
  </Flex.Item>
);

export { TutorialHistory };
