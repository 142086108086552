import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { toast } from 'generic/services/globals';
import { IButton, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../online.constants';

const countryOptions = [
  { key: 'invoice', value: 'invoice', text: 'Factura' },
  { key: 'half_card', value: 'half_card', text: 'Media carta' },
];

const OnlineIndexer = ({ area }) => {
  const {
    item,
    set_item,
    on_number_change,
  } = useFormReducer({});

  const handle_submit = (e) => {
    e.preventDefault();
    api.settings.update(area._id, item)
    .then(() => {
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  const on_type_change = (event, element) => {
    set_item({ ...item, [element.name]: element.value });
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        booking_counter: data.booking_counter || 1,
        sale_counter: data.sale_counter || 1,
        buy_counter: data.buy_counter || 1,
        printer_type: data.printer_type || 'invoice',
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Numeración de checkout</label>
          <input name="booking_counter" type="number" placeholder="1" value={item.booking_counter} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numeración de ventas</label>
          <input name="sale_counter" type="number" placeholder="1" value={item.sale_counter} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numeración de compras</label>
          <input name="buy_counter" type="number" placeholder="1" value={item.buy_counter} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Impresora</label>
          <Select name="printer_type" placeholder="Impresora" value={item.printer_type} onChange={on_type_change} options={countryOptions} />
        </Form.Field>
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

OnlineIndexer.propTypes = {
  area: PropTypes.any.isRequired,
};

export { OnlineIndexer };
