import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Flex, Container } from 'components/grids/v3';
import { VNavbar } from 'components/headers/v1';
import { OnlineDetail } from './online/OnlineDetail';
import { OnlineControlCode } from './online/OnlineControlCode';
import { OnlineInvoice } from './online/OnlineInvoice';
import { OnlineCompany } from './online/OnlineCompany';
import { OnlineLogo } from './online/OnlineLogo';
import { OnlineOrder } from './online/OnlineOrder';
import { OnlineIndexer } from './online/OnlineIndexer';

const Online = ({ area }) => {
  const match = useRouteMatch();
  const [link, set_link] = useState(null);
  const [links] = useState([
    { _id: '1', label: 'Información', icon: 'home', uri: `${match.url}/detail` },
    { _id: '2', label: 'Hotel', icon: 'edit', uri: `${match.url}/company` },
    { _id: '3', label: 'Factura', icon: 'edit', uri: `${match.url}/invoice` },
    { _id: '4', label: 'Control code', icon: 'edit', uri: `${match.url}/code` },
    { _id: '5', label: 'Ordenes', icon: 'edit', uri: `${match.url}/order` },
    { _id: '6', label: 'Numeración', icon: 'edit', uri: `${match.url}/indexer` },
    { _id: '7', label: 'Editar', icon: 'edit', uri: `${match.url}/logo` },
  ]);

  const on_select = useCallback((data) => {
    set_link(data);
  }, []);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <VNavbar link={link} links={links} on_select={on_select} />
        <Switch>
          <Route exact path={`${match.path}/detail`}>
            <OnlineDetail area={area} />
          </Route>
          <Route exact path={`${match.path}/company`}>
            <OnlineCompany area={area} />
          </Route>
          <Route exact path={`${match.path}/invoice`}>
            <OnlineInvoice area={area} />
          </Route>
          <Route exact path={`${match.path}/code`}>
            <OnlineControlCode area={area} />
          </Route>
          <Route exact path={`${match.path}/order`}>
            <OnlineOrder area={area} />
          </Route>
          <Route exact path={`${match.path}/indexer`}>
            <OnlineIndexer area={area} />
          </Route>
          <Route exact path={`${match.path}/logo`}>
            <OnlineLogo area={area} />
          </Route>
        </Switch>
      </Container>
    </Flex>
  );
};

Online.propTypes = {
  area: PropTypes.any.isRequired,
};

export { Online };
