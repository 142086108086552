import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { toast } from 'generic/services/globals';
import { IButton, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../online.constants';

const OnlineCompany = ({ area }) => {
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({});

  const handle_submit = (e) => {
    e.preventDefault();
    api.settings.update(area._id, item)
    .then(() => {
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        area_name: data.area_name || 'Name S.A.',
        area_owner: data.area_owner || 'Dueños S.A.',
        area_headquarter: data.area_headquarter || '',
        area_activity: data.area_activity || 'Ninguna',
        area_nit: data.area_nit || 'NIT000000',
        area_phone: data.area_phone || 'PHONE000000',
        area_address: data.area_address || 'ADDRESS000000',
        area_city: data.area_city || 'CIUDAD000000',
        area_logo: data.area_logo || null,
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="area_name" placeholder="Nombre" value={item.area_name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Dueño</label>
          <input name="area_owner" placeholder="Dueño" value={item.area_owner} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Casa matriz</label>
          <input name="area_headquarter" placeholder="Casa matriz" value={item.area_headquarter} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Actividad economica</label>
          <input name="area_activity" placeholder="Actividad economica" value={item.area_activity} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>NIT</label>
          <input name="area_nit" placeholder="NIT" value={item.area_nit} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Telefono</label>
          <input name="area_phone" placeholder="Telefono" value={item.area_phone} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Dirección</label>
          <input name="area_address" placeholder="Dirección" value={item.area_address} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Ciudad</label>
          <input name="area_city" placeholder="Ciudad" value={item.area_city} onChange={on_string_change} />
        </Form.Field>
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

OnlineCompany.propTypes = {
  area: PropTypes.any.isRequired,
};

export { OnlineCompany };
