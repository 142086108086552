import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { GENERIC } from 'consts';
import { Button } from 'components';
import { Flex } from 'components/grids/v4';
import { StorageService } from 'generic/services/globals';

import { ToolbarList } from './toolbar/ToolbarList';
import { GroupCreateModal } from './modals/GroupCreateModal';

import './Menu.scss';

const Menu = () => {
  const drawer_state = StorageService.load('drawer', true);
  const { account } = useSelector((state) => state.auth);
  const { area } = useSelector((state) => state.apps);
  const { areas } = useSelector((state) => state.areas);
  const [drawer, set_drawer] = useState(drawer_state === null ? true : drawer_state === 'true');
  const [size, set_size] = useState('250px');
  const [open, set_open] = useState(false);
  const [sessionLinks] = useState([]);
  const [options] = useState([
    { icon: 'home', label: 'Hotel', uri: `${GENERIC.APP_BASE_URL}/main` },
    { icon: 'edit', label: 'Tutoriales', uri: `${GENERIC.APP_BASE_URL}/tutorials` },
    { icon: 'male', label: 'Personas', uri: `${GENERIC.APP_BASE_URL}/people` },
    { icon: 'bug', label: 'Mantenimiento', uri: `${GENERIC.APP_BASE_URL}/maintenance` },
    { icon: 'warehouse', label: 'Visitantes', uri: `${GENERIC.APP_BASE_URL}/visitors` },
    { icon: 'long arrow alternate up', label: 'Ventas', uri: `${GENERIC.APP_BASE_URL}/sales` },
    { icon: 'long arrow alternate down', label: 'Compras', uri: `${GENERIC.APP_BASE_URL}/buys` },
    { icon: 'box', label: 'Checkout', uri: `${GENERIC.APP_BASE_URL}/checkout` },
    { icon: 'percent', label: 'Facturas', uri: `${GENERIC.APP_BASE_URL}/invoices` },
    { icon: 'boxes', label: 'Caja diaria', uri: `${GENERIC.APP_BASE_URL}/terminates` },
    { icon: 'tags', label: 'habitaciónes', uri: `${GENERIC.APP_BASE_URL}/rooms` },
    { icon: 'tags', label: 'Tipos de habitaciónes', uri: `${GENERIC.APP_BASE_URL}/types` },
    { icon: 'setting', label: 'Facturacion En linea', uri: `${GENERIC.APP_BASE_URL}/online/detail` },
    { icon: 'setting', label: 'Ajustes', uri: `${GENERIC.APP_BASE_URL}/settings/detail` },
    { icon: 'setting', label: 'Administración', uri: `${GENERIC.APP_BASE_URL}/configuration/detail` },
  ]);

  const handle_drawer = (e) => {
    e.stopPropagation();
    set_drawer(!drawer);
    StorageService.save('drawer', !drawer, true);
  };

  const render_area = (organization) => {
    let organization_label = null;
    if (drawer) {
      organization_label = <h3 className="administrator-title">{organization.name}</h3>;
    } else {
      organization_label = <h3 className="administrator-title-small">{organization.name[0]}</h3>;
    }

    return (
      <div>
        {organization_label}
      </div>
    );
  };

  const render_create_organization = () => (
    <div className="administrator-create-content">
      <Button label="Crear organización" icon="checkmark" onClick={() => set_open(true)} />
      <GroupCreateModal open={open} set_open={set_open} />
    </div>
  );

  useEffect(() => {
    if (drawer) {
      set_size('250px');
    } else {
      set_size('60px');
    }
  }, [drawer]);

  return (
    <Flex.Scrap class_name="drawer" width={size} height="100%" only={['mobile', 'computer', 'screen']} color="#1616163b">
      <Flex direction={Flex.V} color="#1616163b">
        <div aria-hidden="true" className="administrator-drawer-icon" onClick={handle_drawer}>
          <Icon name="list" size="big" />
        </div>
        {account && <ToolbarList links={sessionLinks} select={sessionLinks[1]} />}
        {(account && !areas.length) && render_create_organization()}
        {area && render_area(area)}
        {area && <ToolbarList label={drawer} links={options} />}
      </Flex>
    </Flex.Scrap>
  );
};

export { Menu };
