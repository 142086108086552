const enviroment = process.env.NODE_ENV || 'production';
const ip = '192.168.56.102';
const domain = 'unboliviano';

const BASE_PATH = {
  development: {
    ACCOUNTS: `http://${ip}:10101`,
    FILES: `http://${ip}:10201`,
    SOCKET: `http://${ip}:10401`,

    MONITOR: `http://${ip}:10013`,
    SALES: `http://${ip}:10012`,
    GUESTS: `http://${ip}:10016`,

    APP: `http://${ip}:10016`,
  },
  production: {
    ACCOUNTS: `https://accounts.${domain}.com`,
    FILES: `https://files.${domain}.com`,
    SOCKET: `https://socket.${domain}.com`,

    MONITOR: `https://monitor.${domain}.com`,
    SALES: `https://sales.${domain}.com`,
    GUESTS: `https://hotel.${domain}.com`,

    APP: `https://hotel.${domain}.com`,
  },
}[enviroment];

export { BASE_PATH };
